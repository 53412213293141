<template>
	<div class="d-flex align-items-center min-vh-100">
		<CContainer fluid>
			<CRow class="justify-content-center">
				<CCol md="6">
					<CCard class="mx-4 mb-0">
						<CCardBody class="p-4">
							<CForm>
								<h1>Register</h1>
								<p class="text-muted">Create your account</p>
								<CInput v-model="frm.name" placeholder="Nama Lengkap">
									<template #prepend-content><CIcon name="cil-user"/></template>
								</CInput>
								<CInput v-model="frm.username" placeholder="Username">
									<template #prepend-content><CIcon name="cil-user"/></template>
								</CInput>
								<CInput v-model="frm.email" placeholder="Email" prepend="@" />
								<CInput
									placeholder="Password"
									type="password"
									autocomplete="new-password"
									v-model="frm.password"
								>
									<template #prepend-content
										><CIcon name="cil-lock-locked"
									/></template>
								</CInput>
								<CInput
									placeholder="Repeat password"
									type="password"
									autocomplete="new-password"
									v-model="frm.passwordConfirm"
									class="mb-4"
								>
									<template #prepend-content
										><CIcon name="cil-lock-locked"
									/></template>
								</CInput>
							</CForm>
						</CCardBody>
						<CCardFooter class="p-4">
							<CButton @click="send" color="success" block>
								Create Account
							</CButton>
							<CButton
								@click="$router.push({ name: 'Login' })"
								color="secondary"
								block
							>
								Back to Login
							</CButton>
						</CCardFooter>
					</CCard>
				</CCol>
			</CRow>
		</CContainer>
	</div>
</template>

<script>
import { debounce } from "@/plugins/utils";
export default {
	name: "Register",
	data() {
		return {
			frm: {
				username: "",
				name: "",
				email: "",
				password: "",
				passwordConfrim: "",
			},
		};
	},
	methods: {
		send: debounce(async function() {
			try {
				const { data } = await this.$http.post("register", this.frm);

				if (data.status == "success") {
					this.$toast.success("Success ! " + data.message);
					this.$toast.success("Silahkan Login ");
					this.$router.push({ name: "Login" });
				} else {
					this.$swal("Fail !", data.message, "error");
				}
			} catch (error) {
				this.$swal("Error !", error.message, "error");
			}
		}, 700),
	},
};
</script>
